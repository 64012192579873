

.divMargin{
    padding-top: 30px;
}

.divMarginBreak{
    padding-top: 70px; 
}

.divMarginMidBreak{
    padding-top: 50px;
}

.SignInBackground {
  background-image: url('E:\MedBud\medbud\public\images\Banner.png');
  background-size: cover;
  background-position: center;
}

.SignInBackgroundMedicalTerm {
  background-image: url('E:\MedBud\medbud\public\images\Banner-medical-terminology.png');
  background-size: cover;
  background-position: center;
}

.SignInBackgroundStudyTips {
  background-image: url('E:\MedBud\medbud\public\images\Banner-study-tips.png');
  background-size: cover;
  background-position: center;
}

.SignInBackgroundQuestionDatabase {
  background-image: url('E:\MedBud\medbud\public\images\Banner-question-database.png');
  background-size: cover;
  background-position: center;
}



.transparent-box {
  background-color: rgba(255, 255, 255, 0.5); 
  padding: 20px; 
  width: 70vh;
  border-radius: 10px; 
}


.light-mode {
    /* Light mode styles */
    color: #333;
    background-color: #fff;
  }
  
  .dark-mode {
    /* Dark mode styles */
    color: #ccc;
    background-color: #222;
  }

  .selected-category-dark {
    color: white !important;
  }
  
  .selected-category-light {
    color: black;
  }
  .card-link-anchor {
    text-decoration: none;
  }
  .dropdown-menu {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .dropdown-menu.show {
    opacity: 1;
  }
  .dropdown:hover .dropdown-menu {
    display: block;
    opacity: 1;
    pointer-events: auto;
  }
  .animated-dropdown {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }
  
  .animated-dropdown.show {
    max-height: none; 
  }

  #app{
    min-height: 100vh ;
    display: flex ;
    flex-direction: column;
  }

  #footer{
    margin-top: 0;
  }


  section{
    padding-top: 30px;
  }
  
  .quote-text {
    font-style: italic;
    font-size: 18px;
  }

  .appear {
    animation: pop 0.5s ease forwards;
  }
  
  .pop {
    animation: pop 0.5s ease forwards;
  }
  
  @keyframes pop {
    from {
      transform: scale(0);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .card-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    transition: opacity 0.5s ease;
  }
  
  .pop-in {
    animation: pop-in-animation 0.5s ease;
  }
  
  @keyframes pop-in-animation {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  .centered-navbar {
    display: flex;
  }

.leftSidebar {
  width: 176px;
  height: 250px;
  position: sticky;
  border-radius: 1rem;
  top: 200px;
  margin-left: 5vh;
}

.rightResourceBar {
  height: 250px;
  position: sticky;
  top: 200px;
  margin-right: 5vh
  
}

.sticky-nav-categories {
  position: sticky;
  top: 66px; 
  z-index: 1000; 
}


.meaning-container {
  position: sticky;
  top: 50%;
  transform: translateY(-50%);
}

/* Styles for button sizes based on the window size */
.btn-large {
  padding: 10px 20px;
  font-size: 18px;
}

.btn-small {
  padding: 5px 10px;
  font-size: 14px;
}

@media (max-width: 768px) {
  .btn-small {
    padding: 5px 10px;
    font-size: 12px;  /* even smaller for very small devices */
  }
}

.sidebar-container {
  flex: 0 0 300px; 
  width: 176px;
  height: 250px;
  position: sticky;
  border-radius: 1rem;
  top: 200px;
  margin-left: 5vh;
}

.sidebar-container-resources {
  flex: 0 0 300px; 
  width: 176px;
  height: 250px;
  position: sticky;
  border-radius: 1rem;
  top: 200px;
}


@media (max-width: 992px) {
  .sidebar-container {
    flex: 0 0 200px; 
  }
}

@media (max-width: 768px) {
  .sidebar-container {
    display: none;
  }
}

@media (max-width: 992px) {
  .sidebar-container-resources {
    flex: 0 0 200px; 
  }
}

@media (max-width: 768px) {
  .sidebar-container-resources {
    display: none;
  }
}


@media (max-width: 768px) {
  .question-reference-list {
    display: none;
  }
  .medical-answers {
    display: none;
  }
}

@media (max-width: 992px) {
  .question-reference-list {
    display: none;
  }
  .medical-answers {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  /* Mobile phone styles here */
    .transparent-box {
      background-color: rgba(255, 255, 255, 0.5); 
      padding: 20px; 
      max-width: 40vh;
      border-radius: 10px; 
    }
  }

  @media (max-width: 767px) {
    .hide-on-mobile {
      display: none !important; 
    }
  }
  .bolded-box {
    border: 2px solid lightgreen; 
    padding: 5px;           
    display: inline-block; 
    background-color: lightgreen;
    border-radius: 8px;     
    box-shadow: 0 4px 13px rgba(0, 0, 0, 0.2);
    --primary-color: #0a74da; /* Calming blue */
    --secondary-color: #f4f4f4; /* Light gray */
    --accent-color: #2e8b57; /* Calming green */
    --text-color: #333; /* Dark gray for text */
  }

  .progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    background: var(--red);
    transform-origin: 0%;
  }

  .advertisement-box:hover {
    cursor: pointer; /* Changes the cursor to indicate interaction */
  }